<template>
  <b-overlay
    :show="cargando"
    spinner-variant="primary"
    rounded="sm"
  >
    <b-card
      aria-hidden="true"
    >
      <b-form @submit.prevent="$emit('processCompra', compra)">
        <!-- Compra Info: Input Fields -->
        <b-form>
          <b-card-title>Información</b-card-title>
          <b-row>
            <!-- Field: referencia -->
            <b-col
              cols="12"
              md="4"
            >
              <b-form-group
                label-for="referencia"
                label="Referencia"
              >
                <b-form-input
                  id="referencia"
                  v-model="compra.referencia"
                  placeholder="Introduce la referencia"
                />
              </b-form-group>
            </b-col>

            <!-- Field: idProveedor -->
            <b-col
              cols="12"
              md="4"
            >
              <b-form-group
                label="Proveedor"
                label-for="proveedor"
              >
                <v-select
                  v-model="compra.idProveedor"
                  placeholder="Selecciona un proveedor"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="text"
                  :options="proveedoresOption"
                  :reduce="option => option.value"
                  input-id="idProveedor"
                />
              </b-form-group>
            </b-col>

            <!-- Field: Fecha -->
            <b-col
              cols="12"
              md="4"
            >
              <b-form-group
                label="Fecha"
                label-for="fecha"
              >
                <b-form-datepicker
                  id="fecha"
                  v-model="compra.fecha"
                  local="es"
                  :state="$v.compra.fecha.$invalid == true ? false : null"
                  placeholder="Introduce la fecha"
                  start-weekday="1"
                  :hide-header="true"
                  :show-decade-nav="true"
                  @input="$v.compra.$touch"
                />
                <b-form-invalid-feedback
                  v-if="$v.compra.$dirty"
                  id="fechaInfo"
                >
                  El campo fecha
                  <span v-if="!$v.compra.fecha.required"> es requerido.</span>
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>

            <!-- Field: solicitadoPor -->
            <b-col
              cols="12"
              md="6"
            >
              <b-form-group
                label-for="solicitadoPor"
                label="Solicitado Por"
              >
                <b-form-input
                  id="solicitadoPor"
                  v-model="compra.solicitadoPor"
                  placeholder="Introduce solicitado por"
                />
              </b-form-group>
            </b-col>

            <!-- Field: numCotizacion -->
            <b-col
              cols="12"
              md="6"
            >
              <b-form-group
                label-for="numCotizacion"
                label="Cotización N°"
              >
                <b-form-input
                  id="numCotizacion"
                  v-model="compra.numCotizacion"
                  placeholder="Introduce el número de cotización"
                />
              </b-form-group>
            </b-col>

          </b-row>

          <colLinea />

          <b-card-title class="mt-2">
            Productos
          </b-card-title>
          <b-row>

            <!-- Field: PRODUCTOS -->
            <b-col
              cols="12"
              md="12"
            >
              <comprasProductos
                :cargando.sync="cargando"
                :valorTotal.sync="valorTotal"
                :cantidadesTotal.sync="cantidades"
                :comprasProductos.sync="compra.comprasProductos"
                :update="update"
              />
            </b-col>

          </b-row>

          <colLinea />

          <b-card-title class="mt-2">
            Detalle
          </b-card-title>
          <b-row>
            <!-- Field: tiempoGarantia -->
            <b-col
              cols="12"
              md="12"
            >
              <b-form-group
                label-for="tiempoGarantia"
                label="Tiempo de garantía por"
              >
                <b-form-input
                  id="tiempoGarantia"
                  v-model="compra.tiempoGarantia"
                  placeholder="Introduce el tiempo de garantia"
                />
              </b-form-group>
            </b-col>

            <!-- Field: observaciones -->
            <b-col
              cols="12"
              md="12"
            >
              <b-form-group
                label-for="observaciones"
                label="Observaciones"
              >
                <b-form-textarea
                  id="observaciones"
                  v-model="compra.observaciones"
                  placeholder="Introduce las observaciones"
                  rows="3"
                />
              </b-form-group>
            </b-col>
          </b-row>

          <colLinea />

          <b-card-title class="mt-2">
            Condiciones de venta
          </b-card-title>
          <b-row>

            <!-- Field: LUGAR DE ENTREGA -->
            <b-col
              cols="12"
              md="4"
            >
              <b-form-group
                label="Lugar de entrega"
                label-for="lugarEntrega"
              >
                <b-form-input
                  id="lugarEntrega"
                  v-model="compra.lugarEntrega"
                  placeholder="Introduce el lugar de entrega"
                />
              </b-form-group>
            </b-col>

            <!-- Field: CONDICIONES DE PAGO -->
            <b-col
              cols="12"
              md="4"
            >
              <b-form-group
                label="Condiciones de pago"
                label-for="condicionPago"
              >
                <b-form-input
                  id="condicionPago"
                  v-model="compra.condicionPago"
                  placeholder="Introduce las condiciones de pago"
                />
              </b-form-group>
            </b-col>

            <!-- Field: FECHA ENTREGA -->
            <b-col
              cols="12"
              md="4"
            >
              <b-form-group
                label="Fecha de entrega"
                label-for="fechaEntrega"
              >
                <b-form-datepicker
                  id="fechaEntrega"
                  v-model="compra.fechaEntrega"
                  local="es"
                  placeholder="Introduce la fecha de entrega"
                  start-weekday="1"
                  :hide-header="true"
                  :show-decade-nav="true"
                />
              </b-form-group>
            </b-col>

          </b-row>

          <colLinea />

        </b-form>

        <!-- ACTION BUTTONS -->
        <btnSubmit
          :btnDisabled="cantidades > 0 ? false : true"
          :btnText="compraSubmit"
        />
      </b-form>
    </b-card>
  </b-overlay>
</template>

<script>
import {
  BRow, BCol, BFormGroup, BFormInput, BForm, BFormInvalidFeedback,
  BCardTitle, BFormDatepicker, BFormTextarea, BOverlay, BCard,
} from 'bootstrap-vue'
import { validationMixin } from 'vuelidate'
import {
  required, maxLength,
} from 'vuelidate/lib/validators'
import { soloNumerosTramo, formatos } from '@/mixins/forms'
import { mapActions, mapGetters } from 'vuex'
import { avatarText } from '@core/utils/filter'
import { ref } from '@vue/composition-api'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import vSelect from 'vue-select'

const btnSubmit = () => import('@/layouts/components/Recycled/Form/btnSubmit.vue')
const comprasProductos = () => import('@/layouts/components/Compras/ComprasFormProductos.vue')
const colLinea = () => import('@/layouts/components/Recycled/Form/colLinea.vue')

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BFormInvalidFeedback,
    BCardTitle,
    BFormDatepicker,
    vSelect,
    BFormTextarea,
    BOverlay,
    BCard,
    // Cleave,

    btnSubmit,
    comprasProductos,
    colLinea,
  },
  mixins: [validationMixin, soloNumerosTramo, formatos],
  props: {
    compra: {
      type: Object,
      required: true,
    },
    compraSubmit: {
      type: String, // Texto del boton
      default: 'Guardar Compra',
    },
    update: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      proveedoresOption: [],
      cargando: true,
      cantidades: 0,
      valorTotal: 0,
      descuento: 0,
      options: {
        number: {
          numeral: true,
          numeralThousandsGroupStyle: 'thousand',
        },
      },
    }
  },
  computed: {
    ...mapGetters({
      getProveedoresActivos: 'proveedores/getProveedoresActivos',
      getProveedores: 'proveedores/getProveedores',
      getProductos: 'productos/getProductos',
      getUserAuth: 'auth/authenticated',
    }),
  },
  watch: {
    valorTotal() {
      this.compra.valorTotal = this.valorTotal
    },
    descuento() {
      this.compra.descuento = this.descuento
    },
    getProveedoresActivos() {
      this.getProveedoresActivos.forEach(proveedor => {
        const rutNombre = `${proveedor.rut} - ${proveedor.nombre}`
        this.proveedoresOption.push({
          value: `${proveedor.id}`,
          text: rutNombre,
        })
      })
      // this.cargando = false
    },
  },
  validations() {
    return {
      compra: {
        fecha: {
          required,
        },
        referencia: {
          maxLength: maxLength(200),
        },
      },
    }
  },
  mounted() {
    if (typeof this.compra.fecha.length !== 'undefined') {
      const fecha = this.compra.fecha.split('/')
      if (typeof fecha[2] !== 'undefined' && typeof fecha[1] !== 'undefined') {
        this.compra.fecha = `${fecha[2]}-${fecha[1]}-${fecha[0]}`
      }
    }
    this.fetchProveedoresActivos()
    this.fetchProductos()
    this.compra.idUsuario = this.getUserAuth.id
    if (this.update) {
      this.compra.fecha = this.formatoFecha(this.compra.fecha)
    } else {
      const dia = this.compra.fecha.getDate()
      const mes = (this.compra.fecha.getMonth() + 1)
      const year = this.compra.fecha.getFullYear()
      this.compra.fecha = `${year}-${mes}-${dia}`
    }
  },
  methods: {
    ...mapActions({
      fetchProveedoresActivos: 'proveedores/fetchProveedoresActivos',
      fetchProductos: 'productos/fetchProductos',
    }),
    formatoFecha(fecha) {
      return fecha.replace(/^(\d{2})-(\d{2})-(\d{4})$/g, '$3-$2-$1')
    },
    dateDisabled(ymd, date) {
      // Disable weekends (Sunday = `0`, Saturday = `6`) and
      // disable days that fall on the 13th of the month
      const weekday = date.getDay()
      const day = date.getDate()
      // Return `true` if the date should be disabled
      return weekday === 0 || weekday === 6 || day === 13
    },
    soloNumeros() {
      const expRegNumero = /[^\d]/g
      if (this.compra.valorTotal != null) {
        const validation = expRegNumero.test(this.compra.valorTotal)
        if (validation) {
          this.compra.valorTotal = this.compra.valorTotal.replace(expRegNumero, '')
        }
      }
      if (this.compra.valorTotal.length === 0) {
        this.compra.valorTotal = '0'
      }
    },
  },
  setup() {
    const refInputEl = ref(null)
    const previewEl = ref(null)

    const { inputImageRenderer } = useInputImageRenderer(refInputEl, base64 => {
      // eslint-disable-next-line no-param-reassign
      this.compra.imagen = base64
    })

    return {
      previewEl,
      avatarText,
      inputImageRenderer,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
